import React, { useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'
import { moment } from '../../../utils/moment'
import Layout from '../../../components/Layout'
import { Title, Section, Paragraph } from '../../../components/Typography'
import { ToriokiQuery } from '../../../../types/graphql-types'
import { render } from '../../../utils/render'
import FormText from '../../../components/FormText'
import {
  makeStyles,
  MenuItem,
  ListItemText,
  List,
  ListItem,
} from '@material-ui/core'
import Form from '../../../components/Form'

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  section: { marginTop: theme.spacing(2) },
}))

const Torioki: React.FC<PageProps<ToriokiQuery>> = ({ data }) => {
  const classes = useStyles()
  const now = useMemo(() => moment(), [])
  const futureLives = data.allMicrocmsLives.edges.filter(({ node }) =>
    moment(node.date).isSameOrAfter(now, 'day')
  )
  return (
    <Layout path="Contact">
      <Title>チケット取り置きフォーム</Title>
      <Section className={classes.section}>
        現在取り置きを受け付けているライブ
      </Section>
      {futureLives.length > 0 ? (
        <>
          <List>
            {futureLives.map(({ node }) => (
              <ListItem divider alignItems="flex-start">
                <ListItemText
                  secondary={`${moment(node.date).format('YYYY/M/D (ddd)')} @${
                    node.place
                  }`}
                >
                  {node.title}
                </ListItemText>
              </ListItem>
            ))}
          </List>
          <Section className={classes.section}>
            チケット取り置き（予約）
          </Section>
          <Paragraph>
            下記フォームへ入力し「送信」ボタンを押してください。
          </Paragraph>
          <Form
            name="Torioki"
            action="/contact/torioki/success/"
            className={classes.form}
          >
            <FormText name="name" label="お名前" required />
            <FormText
              name="email"
              label="メールアドレス"
              type="email"
              required
            />
            <FormText name="live" label="取り置きするライブ" select required>
              {futureLives.map(({ node }) => {
                const date = moment(node.date).format('YYYY/M/D (ddd)')
                return (
                  <MenuItem
                    key={node.livesId}
                    value={`${date} ${node.title}@${node.place}`}
                    alignItems="flex-start"
                  >
                    <ListItemText
                      secondary={`${date} @${node.place}`}
                      primaryTypographyProps={{ noWrap: true }}
                      secondaryTypographyProps={{ noWrap: true }}
                    >
                      {node.title}
                    </ListItemText>
                  </MenuItem>
                )
              })}
            </FormText>
            <FormText
              name="count"
              label="枚数"
              type="number"
              required
              defaultValue={1}
              inputProps={{ min: 1 }}
            />
            <FormText name="body" label="備考" multiline rows={10} />
          </Form>
        </>
      ) : (
        <Paragraph>現在取り置きを受け付けているライブはありません。</Paragraph>
      )}

      <div>{render(data.microcmsIndex?.torioki)}</div>
    </Layout>
  )
}

export const query = graphql`
  query Torioki {
    microcmsIndex {
      torioki
    }
    allMicrocmsLives(sort: { fields: date, order: DESC }) {
      edges {
        node {
          date
          livesId
          title
          place
        }
      }
    }
  }
`

export default Torioki
